import ReconnectingWebSocket from 'reconnecting-websocket'

// eslint-disable-next-line no-unused-vars
class WebSocketOptions {
  data
  cache
  destroyCache

  constructor () {
    this.data = null
    //좌표 경도 Longitude
    this.cache = null
    //좌표 위도 Latitude
    this.destroyCache = null
  }
}

/**
 * ConnectWebSocket
 * TODO: messageHandler: EventEmitter。콜백 함수를 일시적으로 전달
 */
class ConnectWebSocket {
  _url
  _socket
  _hasInit
  _messageHandler
  _protocol

  constructor (url) {
    this._url = url
    this._socket = null
    this._hasInit = false
    this._messageHandler = null
  }

  initSocket () {
    if (this._hasInit) {
      return
    }
    if (!this._url) {
      return
    }
    // 자동으로 다시 연결되며 다시 연결 논리를 처리할 필요가 없습니다.
    this._socket = new ReconnectingWebSocket(this._url, null,{
      maxReconnectionDelay: 20000, // 연결 해제 후 최대 재연결 시간: 20초, 추가로 재연결할 때마다 1.3배, 5 * 1.3 * 1.3 * 1.3...
      minReconnectionDelay: 5000, // 연결 해제 후 최소 재연결 시간: 5초
      maxRetries: 5
    })

    this._hasInit = true
    this._socket.debug = true;

    this._socket.onclose = function(e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e);
    };

    this._socket.onerror = function(err) {
      console.error('Socket encountered error: ', err, 'Closing socket');
    };

    this._socket.addEventListener('open', this._onOpen.bind(this))
    this._socket.addEventListener('close', this._onClose.bind(this))
    this._socket.addEventListener('error', this._onError.bind(this))
    this._socket.addEventListener('message', this._onMessage.bind(this))

  }

  _onOpen (obj) {
    console.log('open',obj)
  }

  _onClose (obj) {
    console.log('close', obj)
  }

  _onError (error) {
    console.log('error',error)
  }

  registerMessageHandler(messageHandler) {
    this._messageHandler = messageHandler
  }

  _onMessage (msg) {
    const data = JSON.parse(msg.data)
    this._messageHandler && this._messageHandler(data)
    // console.log('接受消息', message)
  }

  close() {
    this._socket?.close()
  }

  sendMessage = (message) => { this._socket?.send(JSON.stringify(message.data))}
}

export default ConnectWebSocket
