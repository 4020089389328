// import { onMounted, onUnmounted } from 'vue'
import ConnectWebSocket from '@/station/websocket'
import { getWebsocketUrl } from '@/station/websocket/util/config.js'

var webSocket = null
var messageHandler = null

/**
 * 메시지 수신 기능
 * @param messageHandler
 */
export async function useConnectWebSocket(_messageHandler, eventId) {
  messageHandler = _messageHandler
  webSocket = new ConnectWebSocket(getWebsocketUrl(eventId))
}

export var onWebSocketMounted = (() => {
  if(webSocket != null) {
    webSocket.registerMessageHandler(messageHandler)
    webSocket.initSocket()
  }
})

export var onWebSocketUnmounted = (() => {
  if(webSocket != null) {
    webSocket.close()
  }
})


