import { StationWebsocketURL } from '@/config/env'
import {ELocalStorageKey} from "@/api/station/dji/enums.js";

export function getWebsocketUrl(eventId) {
  const token = localStorage.getItem("token") === undefined ? null: localStorage.getItem("token")
  var url = StationWebsocketURL + `?${ELocalStorageKey.Token}=${encodeURI(token)}`
  if(eventId != null && eventId != undefined) {
    url = url + `&eventId=${eventId}`
  }
  return url
}
