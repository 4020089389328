<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-device-sub-drone-station") }}
        </h1>
        <div class="content-row m-b-20">
            <div class="map-content">
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"
                        :isLeftButtonPosition="false" @map-move-center="onMapCenter" />
                    <span v-if="locationSize == 0"
                        style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                        {{ $t("popup-location-not-found") }}
                    </span>
                </div>
                <div class="time-list content-box">
                    <div class="time-list-title">
                        <h2>스케줄 목록</h2>
                        <select v-model="timeLineSearchData.selectType" @change="timeLineChangeStatus">
                            <option v-for="item in timeLineSearchData.searchTypes" :value="item.value"
                                :key="item.value">{{ item.name }}</option>
                        </select>
                    </div>
                    <ul>
                        <li v-for="item in timeLineList" :key="item.localTime">
                            <div class="time-list-row">
                                <span class="time-list-mark"
                                    :class="checkTime == item.localTime.substring(0, 5) ? 'schedule-on' : 'schedule-off'"></span>
                                <p>{{ item.localTime.substring(0, 5) }}</p>
                                <h4>ㆍㆍㆍ</h4>
                                <h3>{{ (item.localTime.substring(0, 5) > checkTime && checkScheduleTimeLineRunCount(item) == 0) ? "대기중" :
                                        (  checkScheduleTimeLineRunCount(item) > 0 || item.localTime.substring(0, 5) == checkTime) ? "작업중" : "종료" }}</h3>
                            </div>
                            <div class="station-number-mark">
                                <span v-for="(station, idx) in item.items" :class="checkScheduleStationIsRun(station) == true ? 'mission-on' : '' " :key="idx + station.stationDeviceId">{{ station.stationDeviceTag }}</span>
                                <!--                                <span class="mission-on">1</span>-->
                                <!--                                <span class="mission-on">2</span>-->
                                <!--                                <span class="rth-on">3</span>-->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-content">
                <div class="info-content-row">
                    <div class="weather-box content-box">
                        <div class="weather-box-title">
                            <h2>기상정보
                                <a @click="getWeatherList" style="line-height: 1.2rem;"><!--새로고침 버튼-->
                                    <img src="@/assets/images/icon_svg/ic_refresh.svg" alt="icon"
                                        style="width: 1.5rem; margin-left: 10px;" />
                                </a>
                            </h2>
                            <div>
                                <p>
                                    {{ $t("site-view-weather-update") }} : <span>{{ weatherUpdate == "" || weatherUpdate ==
                                        undefined ? " - " : moment(weatherUpdate).format("YYYY.MM.DD HH:mm") }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="flexB">
                            <div class="weatherWrap flex">
                                <div class="cover" v-for="(data, i) in weatherList" :key="i">
                                    <h2>
                                        {{ data.weatherEventDataId.fcstDateTime.substr(8, 2) + "시" }}
                                    </h2>
                                    <img :src="require(`@/assets/images/icon_svg/${imgBinding(data)}.svg`)"
                                        alt="icon" />
                                    <p>{{ data.temperatures == undefined ? '-' : data.temperatures.split(" ")[0]
                                        }}&#8451;</p>

                                    <img v-if="data.windDirection != undefined"
                                        src="@/assets/images/icon_svg/ic_w_wind.svg" alt="icon"
                                        :style="`transform: rotate(${data.windDirection.replace(' ', '')})`" />
                                    <img v-if="data.windDirection == undefined"
                                        src="@/assets/images/icon_svg/ic-minus.svg" />

                                    <p>
                                        {{ data.windDirection == undefined ? '-' :
                                            convertWindDirection(data.windDirection.split(" ")[0]) }}<br />
                                        {{ data.windDirection == undefined ? '-' : data.windSpeed }}
                                    </p>
                                    <img :src="require(`@/assets/images/icon_svg/${humidityBinding(data.humidity)}.svg`)"
                                        alt="icon" />
                                    <!-- 습도 img 파일명 /  humidity_1 ~ humidity_5  -->
                                    <p>{{ data.humidity == undefined ? '-' : data.humidity }}</p>
                                    <!--<p>{{ data.rain == undefined || data.rain == "강수없음" ? '0mm' : data.rain }}</p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="noTam-box content-box">
                        <h2>NOTAM</h2>
                        <h4>추후 업데이트 예정중...</h4>
                    </div>
                </div>
                <div class="station-controller content-box">
                    <div class="station-controller-title">
                        <h4><img src="@/assets/images/top_menu_icon_c4.png" />스테이션 제어</h4>
                    </div>
                    <div class="station-controller-btn">
                        <button @click="selectAll">전체선택</button>
                        <button @click="deselectAll">전체해제</button>
                        <button class="start-btn" @click="stationScheduleChangeAll(true)">스케줄시작</button>
                        <button class="stop-btn" @click="stationScheduleChangeAll(false)">스케줄중지</button>
                    </div>
                </div>
            </div>`
        </div>
        <div class="content-row">
            <div class="station-list-row">
                <div v-for="(n, index) in 12" :key="index" class="station-box">
                    <div class="station-vod">
                        <div class="play-on" v-if="getStationListInfo(index) != null && getStationListInfo(index).device.modeCode != null && getStationListInfo(index).device.modeCode != EDockModeCode.Disconnected.code">
                          <input style="position: absolute" type="checkbox" :value="getStationListInfo(index) != null && getStationListInfo(index).device.stationDeviceId" v-model="checkedStations" />
                          <img style="height: 100%; width: 100%" :key="getStationDeviceThumbnailKeyTimestamp(getStationListInfo(index) == null ? null : getStationListInfo(index).device.stationDeviceId) " :src="getStationDeviceThumbnail(getStationListInfo(index) == null ? null : getStationListInfo(index).device.stationDeviceId)">
                        </div>
                        <div class="play-off" v-if="getStationListInfo(index) == null || getStationListInfo(index).device.modeCode == null || getStationListInfo(index).device.modeCode == EDockModeCode.Disconnected.code">
                            <img src="@/assets/images/play-off-icon.png" alt="icon" />
                            <h2>영상 신호 없음</h2>
                        </div>
                    </div>
                    <div class="station-info">
                        <div class="station-info-detail">
                            <div class="station-title">
                                <h4 class="m-b-5">{{ getStationListInfo(index) == null ? '-' :
                                    getStationListInfo(index).device.managerData.tag + '번 ' +
                                    getStationListInfo(index).device.deviceName }}</h4>
                                <p><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />{{ getStationListInfo(index) == null ? '-' : getStationListInfo(index).children.deviceName }}</p>
                            </div>
                            <div class="station-situation">
                                <div class="situation-row m-b-5">
                                  <span :class="getStationListInfo(index) == null || getStationListInfo(index).device.modeCode == null || getStationListInfo(index).device.modeCode == EDockModeCode.Disconnected.code ? 'station-off' : 'station-on' "></span>
                                  <p>{{ getStationListInfo(index) == null || getStationListInfo(index).device.modeCode == null ? EDockModeCode.getData(EDockModeCode.Disconnected).showName : EDockModeCode.getData(getStationListInfo(index).device.modeCode).showName  }}</p>
                                </div>
                                <div class="situation-row">
                                  <span :class="getStationListInfo(index) == null || getStationListInfo(index).children.modeCode == null || getStationListInfo(index).children.modeCode == EModeCode.Disconnected.code ? 'station-off' : 'station-on' "></span>
                                  <p>{{ getStationListInfo(index) == null || getStationListInfo(index).children.modeCode == null ? EModeCode.getData(EModeCode.Disconnected).showName : EModeCode.getData(getStationListInfo(index).children.modeCode).showName  }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="station-bottom">
                            <button
                                @click="stationOpen(getStationListInfo(index) == null ? null : getStationListInfo(index).device.stationDeviceId)">상세정보 이동하기</button>
                            <div class="scb-btn">
                                <button v-if="getStationListInfo(index) != null"
                                    @click="stationScheduleChange(getStationListInfo(index).device.stationDeviceId, getStationListInfo(index).device.managerData.isScheduleRun != true)">
                                    <img v-if="getStationListInfo(index).device.managerData.isScheduleRun != true"
                                        src="@/assets/images/ic_st_start.png" alt="icon" />
                                    <img v-if="getStationListInfo(index).device.managerData.isScheduleRun == true"
                                        src="@/assets/images/ic_st_pause.png" alt="icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.title {
    display: none;
}

.lnb {
    display: none;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.map-content {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.map-content>.map {
    width: 65%;
    height: 350px;
}

.time-list {
    width: calc(35% - 20px);
    height: 350px;
}

.time-list>.time-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.time-list>.time-list-title>h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
}

.time-list>.time-list-title>select {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
    font-weight: 400;
    width: 120px;
    border-radius: 5px;
}

.time-list>ul {
    max-height: 270px;
    height: 270px;
    overflow-y: scroll;
}

.time-list>ul>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.time-list::-webkit-scrollbar {
    width: 5px;
}

.time-list::-webkit-scrollbar-thumb {
    background-color: #969696;
    border-radius: 4px;
}

.time-list-row {
    display: flex;
    align-items: center;
}

.time-list-row>.time-list-mark {
    width: 13px;
    height: 13px;
    background: #ddd;
    border-radius: 10px;
    margin-right: 5px;
}

.time-list-row>.time-list-mark.schedule-on {
    background: #32e783;
}

.time-list-row>p {
    font-size: 1.2rem;
    width: 35px;
}

.time-list-row>h3 {
    font-size: 1.2rem;
    font-weight: 400;
}

.time-list-row>h4 {
    font-size: 1.2rem;
    color: #969696;
    margin: 0 5px 0 0;
    letter-spacing: -5px;
}

.station-number-mark {
    padding-right: 5px;
    display: flex;
    align-items: center;
}

.station-number-mark>span {
    width: 20px;
    height: 20px;
    margin-left: 3px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #000;
    background: #eee;
}

.station-number-mark>span.mission-on {
    background: #0ECE4E;
    color: #fff;
}

.station-number-mark>span.rth-on {
    background: #FF4747;
    color: #fff;
}

.info-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: 10px;
}

.info-content-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 270px;
    margin-bottom: 20px;
}

.weather-box {
    width: 60%;
    height: 100%;
}

.weather-box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.weather-box-title>h2 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.noTam-box {
    width: calc(40% - 20px);
    height: 100%;
}

.noTam-box>h2 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: bold;
}

.noTam-box>h4 {
    font-size: 1.2rem;
    color: #969696;
    font-weight: 400;
    text-align: center;
    margin-top: 100px;
}

.station-controller {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0080ff;
}

.station-controller-title {
    display: flex;
    align-items: center;
}

.station-controller-title>h4 {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    color: #fff;
}

.station-controller-title>h4>img {
    width: 20px;
    margin-right: 10px;
}

.station-controller-title>p {
    font-size: 1.4rem;
    color: #ffffffcc;
    margin-left: 30px;
}

.station-controller-btn {
    display: flex;
    align-items: center;
}

.station-controller-btn>button {
    border: 1px solid #fff;
    border-radius: 5px;
    margin-left: 5px;
    padding: 0 15px;
    color: #fff;
}

.station-controller-btn>button.stop-btn {
    background: #ff5151;
    color: #fff;
    border: 0;
    margin-left: 5px;
}

.station-controller-btn>button.start-btn {
    background: #0848B5;
    color: #fff;
    border: 0;
    margin-left: 15px;
}

.station-list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.station-box {
    width: 15.6666%;
    margin-bottom: 20px;
}

.station-vod {
    background: #eee;
    width: 100%;
    height: 125px;
    border-radius: 5px;
}

.station-vod>.play-on {
    width: 100%;
    height: 100%;
}

.station-vod>.play-off {
    width: 100%;
    height: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.station-vod>.play-off>img {
    width: 25px;
    margin-bottom: 10px;
}

.station-vod>.play-off>h2 {
    color: #fff;
    font-size: 1.2rem;
}

.station-info {
    background: #fff;
    padding: 8px 10px;
}

.station-info>.station-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.station-info>.station-bottom>button {
    border: 0;
    padding: 0;
    height: auto;
    color: #2f5ded;
}

.station-info>.station-bottom>.scb-btn {
    border: 0;
    padding: 0;
    height: auto;
}

.station-info>.station-bottom>.scb-btn>button {
    border: 0;
    width: 25px;
    margin: 0 2px;
    height: 18px;
}

.station-info>.station-bottom>.scb-btn>button>img {
    width: 100%;
}

.station-info-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
}

.station-title>h4 {
    font-size: 1.4rem;
    font-weight: 600;
}

.station-title>p {
    font-size: 1.2rem;
    color: #969696;
    display: flex;
    align-items: center;
}

.station-title>p>img {
    width: 17px;
    margin-right: 5px;
}

.situation-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.situation-row>span {
    width: 10px;
    height: 10px;
    background: #eee;
    border-radius: 10px;
    margin-right: 10px;
}

.situation-row>p {
    font-size: 1.2rem;
    color: #969696;
}

.situation-row>span.station-on {
    background: #32e783;
}
.weatherWrap{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 15px;
}
.weatherWrap > .cover{
    text-align: center;
}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import { fetchLocation } from "@/api/location";

import { fetchEvent } from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";

import { mapState } from "vuex";
import { getDefaultMapZoomLevel } from "@/util/common";
import { stationPopup } from "@/util/popup";
import { ko } from "vuejs-datepicker/dist/locale";

import windDirection from "@/util/windDirection";

stationPopup

//스테이션 API
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";
import { updateStationDeviceManagerScheduleChange, updateStationDeviceManagerScheduleChangeList } from "@/api/station/stationDeviceManager";
import { fetchStationScheduleTimeline, getScheduleTypes } from "@/api/station/stationSchedule";
import { useConnectWebSocket, onWebSocketMounted, onWebSocketUnmounted } from "@/station/websocket/use-connect-websocket";
import { messageHandler } from "@/station/websocket/message-handler-websocket";
import { EDockModeCode, EModeCode } from '@/station/type/device'
import { fetchWeatherEvent, imgBinding, humidityBinding } from "@/api/weather";
import { fetchStationMissionJob} from "@/api/station/stationMissionJob";
// import { fetchStationThumbnailDeviceUrl} from "@/api/station/stationThumbnail";



export default {
    components: { Map },
    name: "Main",
    props: {
        eventId: {
            type: String,
            default: "",
        },
        address: {
            type: String,
            default: "",
        },

    },
    data() {
        return {
            EDockModeCode : EDockModeCode,
            EModeCode: EModeCode,
            //index: 0,
            moment: moment,
            loadMap : 'kakao',
            viewSrc: "",

            isdrModelShow:true,
            eventInfo : null,
            contentsId: null,
            location: [],
            lat : null,
            lng : null,
            locationSize : null,
            ko: ko,
            startYmd: null,
            endYmd: null,
            currentPage: 0,
            size: 10,
            keywordType: "all",
            keyword: "",
            total: 0,

            checkedStations: [],

            //station
            stationDeviceList: [],


            timeLineSearchData: {
                selectType: null,
                searchTypes: getScheduleTypes(),
            },
            timeLineList: [],
            updateClockTimer: null,
            checkTime: moment().format("HH:mm"),

            weatherList: null,
            weatherUpdate: null,

            thumbnail : {},
            tempBase64 : null,
            tempTimestamp : null,
            md5 : null,
        };
    },
    computed: {

        ...mapState({
            currentLang: state => state.store.currentLang,
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
            dockInfo: state => state.station.deviceDockInfo,
            droneInfo: state => state.station.deviceDroneInfo,
            deviceThumbnailInfo: state => state.station.deviceThumbnailInfo,
        }),
    },
    watch : {
        currentLang(value){
          this.initMapData(value);
        },
        dockInfo : {
            handler(info){
                let findIndex = this.stationDeviceList.findIndex((find) => info[find.device.deviceSerial] != undefined)
                if(findIndex == -1) {
                  return
                }
                let stationDevice = this.stationDeviceList[findIndex]
                let dockInfo = info[stationDevice.device.deviceSerial]
                if(dockInfo.base == undefined ) {
                  return
                }

                if(dockInfo.base.latitude != 0 && dockInfo.base.longitude != 0 && stationDevice.device.latitude != dockInfo.base.latitude && stationDevice.device.longitude != dockInfo.base.longitude) {
                  stationDevice.device.managerData.lat = dockInfo.base.latitude;
                  stationDevice.device.managerData.lng = dockInfo.base.longitude;
                  this.drawStationMarker(stationDevice);
                  this.$set(this.stationDeviceList, findIndex, stationDevice);
                }
                if(dockInfo.base.modeCode != stationDevice.device.modeCode) {
                  if(dockInfo.base.modeCode == EDockModeCode.Working.code) {
                    this.getStationMissionJob(stationDevice.device.stationDeviceId)
                  } else {
                    this.setIsScheduleRun(stationDevice.device.stationDeviceId, stationDevice.device.stationScheduleId, false)
                  }
                  stationDevice.device.modeCode = dockInfo.base.modeCode;
                  this.$set(this.stationDeviceList, findIndex, stationDevice);
                }
            },
            deep: true
        },
        droneInfo : {
            handler(info){
                let findIndex = this.stationDeviceList.findIndex((find) => info[find.children.deviceSerial] != undefined)
                if(findIndex == -1) {
                  return
                }
                let stationDevice = this.stationDeviceList[findIndex]
                let droneInfo = info[stationDevice.children.deviceSerial];

                if(droneInfo.modeCode != stationDevice.children.modeCode) {
                  stationDevice.children.modeCode = droneInfo.modeCode;
                  this.$set(this.stationDeviceList, findIndex, stationDevice);
                }
            },
            deep: true
        },
        deviceThumbnailInfo : {
          handler(info) {
            let keys = Object.keys(info)
            keys.forEach((key) => {
              if(this.thumbnail[key] != undefined) {
                if(this.md5 != this.thumbnail[key].md5) {
                  this.md5 = this.thumbnail[key].md5
                  this.tempTimestamp = this.thumbnail[key].timestamp
                }
              }
              this.$set(this.thumbnail, key, info[key]);
            })
          },
          deep: true
        }
    },
    mounted() {
        // await this.getSDList();
        this.getEvent();
        this.getVodList();
        this.initMapData(this.$store.state.store.currentLang);

        this.updateClockTimer = setInterval(() => {
            this.updateClock()
        }, 1000);

        //statio api
        this.getScheduleTimeLine();
        this.getStationDeviceDockList();
        onWebSocketMounted();
    },
    created() {
        this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
        useConnectWebSocket(messageHandler, this.eventId);
        this.getWeatherList()
    },
    methods: {
        humidityBinding,
        imgBinding,
        getWeatherList() {
            this.weatherList = []
            fetchWeatherEvent(this.eventId).then((res) => {
                this.weatherList = res.data.data.WeatherEventDataList
                this.weatherUpdate = res.data.data.apiRequestDate;
            })
        },
        convertWindDirection(data) {
            return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
        },

        updateClock() {
            this.checkTime = moment().format("HH:mm");
        },

        //region 스테이션 API ------------------------------------------------------------
        getStationDeviceThumbnailKeyTimestamp(stationDeviceId){
          return stationDeviceId == null || this.thumbnail[stationDeviceId] === undefined ? stationDeviceId : stationDeviceId + this.thumbnail[stationDeviceId].timestamp
        },
        getStationDeviceThumbnail(stationDeviceId) {
          if(stationDeviceId == null) {
            return require("@/assets/images/play-off-icon.png")
          }

          let thumbnail = this.thumbnail[stationDeviceId]
          if(thumbnail == undefined) {
            return require("@/assets/images/play-off-icon.png")
          }
          return 'data:image/jpeg; base64,' + thumbnail.base64Encoder
        },
        checkScheduleStationIsRun(scheduleStation) {
          return scheduleStation.isScheduleRun
        },
        checkScheduleTimeLineRunCount(timeLineItem){
          let filter = timeLineItem.items.filter(item => item.isScheduleRun);
          return filter.length;
        },
        getStationListInfo(idx) {
            if (this.stationDeviceList.length < idx - 1) {
                return null
            }
            return this.stationDeviceList[idx]
        },
        getStationDeviceDockList() {
            let params = {
                eventId: this.eventId
            }
            fetchStationDeviceDockList(params).then((res) => {
                if (res.status == 200 && res.data.result == 0) {
                    this.stationDeviceList = res.data.data.content.sort((a, b) => a.device.managerData.tag - b.device.managerData.tag);
                    this.stationDeviceList.forEach((item) => {
                      if(item.device.managerData.lat != null && item.device.managerData.lng != null) {
                        this.drawStationMarker(item);
                      }
                      item.device.modeCode = null;
                      item.children.modeCode = null;
                      this.storeDockInfo = this.$store.getters["station/GET_DOCK_INFO"](item.device.deviceSerial);
                      this.storeDroneInfo = this.$store.getters["station/GET_DRONE_INFO"](item.children.deviceSerial);
                    });
                }

            }).catch((err) => {
              console.log("getStationDeviceDockList")
              console.log("err")
              console.log(err)
            });
        },
        drawStationMarker(stationDeviceInfo) {
          if(stationDeviceInfo == null) {
            return
          }
          if (this.$refs.map === undefined) {
            return
          }

          const mapDataType = MapDataType.ID_SCHEDULE_STATION
          // const index = Number(item.device.managerData.stationDeviceId)
          const mapMarkerData = new MapMarkerData();

          mapMarkerData.id = stationDeviceInfo.device.stationDeviceId;
          mapMarkerData.name = stationDeviceInfo.device.deviceName;
          // mapMarkerData.lng = item.lng;
          // mapMarkerData.lat = item.lat;
          mapMarkerData.lng = stationDeviceInfo.managerData.lng;
          mapMarkerData.lat = stationDeviceInfo.managerData.lat;

          if (this.$refs.map.onIsMarker(mapDataType, stationDeviceInfo.device.stationDeviceId)) {
            this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
          } else {
            this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
          }
        },
        //endregion 스테이션 API ------------------------------------------------------------

      //스케줄러 개벌 업데이트(true - 시작, false - 중지)
        updateStationDeviceScheduleChange(stationDeviceId, isScheduleRun) {
            let params = {
                isScheduleRun: isScheduleRun,
            }
            updateStationDeviceManagerScheduleChange(stationDeviceId, params).then((res) => {
                if (res.status == 200 && res.data.result == 0) {
                    this.stationDeviceList.forEach((item) => {
                        if (item.device.stationDeviceId == stationDeviceId) {
                            item.device.managerData.isScheduleRun = isScheduleRun
                        }
                    });
                    if (isScheduleRun) {
                        alert('스테이션에 스케줄이 시작되었습니다.');
                    } else {
                        alert('스테이션에 스케줄이 중지되었습니다.');
                    }
                } else {
                    alert('스테이션에 스케줄 상태 변경이 실패 했습니다.');
                }
            }).catch((err) => {
                alert('스테이션 스케줄 상태 변경 요청이 실패 했습니다.');
                console.log(err)
            });
        },

        //스케줄러 전체 업데이트(true - 시작, false - 중지)
        updateStationDeviceScheduleChangeList(stationDeviceIds, isScheduleRun) {
            let params = {
                isScheduleRun: isScheduleRun,
                list: stationDeviceIds
            }

            updateStationDeviceManagerScheduleChangeList(params).then((res) => {
                if (res.status == 200 && res.data.result == 0) {
                    if (isScheduleRun) {
                        alert('스테이션에 스케줄이 시작되었습니다.');
                    } else {
                        alert('스테이션에 스케줄이 중지되었습니다.');
                    }
                    this.deselectAll();
                    this.getStationDeviceDockList();
                }
            }).catch((err) => {
                alert('스테이션 스케줄 상태 변경 요청이 실패 했습니다.');
                console.log(err)
            });
        },

        timeLineChangeStatus() {
            this.getScheduleTimeLine();
        },

        getScheduleTimeLine() {
            let beginLocalDate = moment().format("YYYY-MM-DD");
            let endLocalDate = moment().format("YYYY-MM-DD");

            let params = {
                beginLocalDate: beginLocalDate,
                endLocalDate: endLocalDate,
                scheduleType: this.timeLineSearchData.selectType,
                eventId: this.eventId,
            }
            fetchStationScheduleTimeline(params).then((res) => {
                if (res.status == 200 && res.data.result == 0) {
                    if (res.data.data[0].localDate == beginLocalDate) {
                        let tempList = res.data.data[0].items;
                        if (tempList.length > 0) {
                            tempList.forEach((item) => {
                                item.isScheduleRun = false;
                                let findIndex = this.timeLineList.findIndex((x) => x.localDate == item.localDate && x.localTime == item.localTime)
                                var pushData = null
                                if (findIndex == -1) {
                                    var create = {
                                        localDate: item.localDate,
                                        localTime: item.localTime,
                                        items: []
                                    }
                                    this.timeLineList.push(create)
                                    pushData = create.items
                                } else {
                                    pushData = this.timeLineList[findIndex].items
                                }
                                pushData.push(item)
                            })
                        }
                    }
                }
            });
        },

        setIsScheduleRun(stationDeviceId, stationScheduleId, isScheduleRun) {
          let findIndex = this.stationDeviceList.findIndex((find) => find.device.stationDeviceId == stationDeviceId )
          if(findIndex != -1) {
            if(isScheduleRun) {
              this.stationDeviceList[findIndex].device.stationScheduleId = stationScheduleId
            } else {
              this.stationDeviceList[findIndex].device.stationScheduleId = null
            }

            for(let idx in this.timeLineList) {
              let item = this.timeLineList[idx];
              if(item.items.length > 0) {
                let findItemIndex = item.items.findIndex((find) => find.scheduleId == stationScheduleId)
                if(findItemIndex != -1) {
                  item.items[findItemIndex].isScheduleRun = isScheduleRun;
                  break;
                }
              }
            }
          }
        },

        async getStationMissionJob(stationDeviceId) {
          let response = await fetchStationMissionJob(stationDeviceId)
          if(response.code != 200 && response.data.result != 0) {
            return
          }
          let stationScheduleId = response.data.data.stationMissionJobLog.stationScheduleId
          if(stationScheduleId == null) {
            return
          }

          this.setIsScheduleRun(stationDeviceId, stationScheduleId, true)
        },

        //endregion 스테이션 API ------------------------------------------------------------

        initMapData(value) {
            if (value == 'en') {
                this.loadMap = 'google'
            } else {
                this.loadMap = 'kakao'
            }
            this.getEventPoint();
            this.setMapSetting();
            if (this.contentsId != null) {
                this.getLocation(this.contentsId);
            }
        },
        setMapSetting() {
            this.$refs.map.onShowCenterPoint();
            this.$refs.map.onShowButtonTypeChange();
            //this.$refs.map.onShowLngLat();
        },
        getEvent() {
            if (this.eventId == undefined) {
                return;
            }
            fetchEvent(this.eventId).then((res) => {
                if (res.data.result == 0) {
                    this.eventInfo = res.data.data;
                    if (this.$refs.map == undefined) {
                        return;
                    }
                    if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
                        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
                        this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
                        this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
                    } else {
                        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
                    }
                }
            });
        },
        onMapCenter() {
            if (this.eventInfo == null) {
                return;
            }
            this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
            if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
                this.lng = this.eventInfo.lng;
                this.lat = this.eventInfo.lat;
                this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
                this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            } else {
                this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
            }
        },
        async getEventPoint() {
            let params = {
                eventId: this.eventId,
                serverTime: null,
            };
            await fetchPointList(params).then((res) => {
                if (res.data.result == 0) {
                    this.pointServerTime = res.data.data.serverTime
                    this.pointCount = res.data.data.total
                    this.pointList = res.data.data.content
                    this.pointList.forEach((element) => {
                        element.selectId = element.index;
                        element.selectType = "Point";
                        element.isOwner = false;
                        let pointMapMarker = new MapMarkerData();
                        element.name = this.$t(getPointTypeName(element.type));
                        pointMapMarker.setPointData(element);
                        this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
                    })
                }
            });
        },
        onTimeUpdate(time) {
            if (this.locationSize > 0) {
                this.$refs.map.onPlayUpdatePosition(time);
            }
        },
        async getLocation(contentsId) {
            this.location = [];
            this.initDoneData();

            this.$refs.map.onPlayRemoveData()
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
            let params = {
                contentsId: contentsId,
            };
            this.locationSize = null;
            await fetchLocation(params).then((res) => {
                this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
                if (res.data.result == 0) {
                    this.locationSize = res.data.data.content.length;
                    if (this.locationSize == 0) {
                        return;
                    }
                    this.location = res.data.data.content.sort(function (a, b) {
                        return a.index - b.index;
                    })
                    var first = res.data.data.content[0];
                    this.lng = first.lng;
                    this.lat = first.lat;
                    this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
                    var mapLineData = new MapLineData();
                    mapLineData.id = this.contentsId;
                    mapLineData.locationGroup = res.data.data.content;
                    this.$refs.map.onPlayCreateData(mapLineData);
                } else {
                    this.locationSize = null;
                }
            });
        },
        async viewVideo(data) {
            this.viewSrc = data.src;
            this.isPlayStatus = false;

            if (this.contentsId != data.contentsId) {
                this.contentsId = data.contentsId;
                await this.getLocation(this.contentsId);
            }
        },
        customFormatter(date) {
            return moment(date).format("YYYY년 MM월 DD일");
        },
        search() {
            if (this.startYmd != null) {
                if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
                    return alert(this.$t("alert-message-check-regdate"));
                }
            }
            this.currentPage = 0;
            this.getVodList();
        },
        getVodList() {
            if (this.$refs.map != undefined) {
                this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
            }

            let params = {
                pageNumber: this.currentPage,
                pageSize: 8,
                eventId: this.eventId,
                deviceType: 'Drone,Mobile',
                keyword: this.keyword,
                keywordType: this.keywordType,
                eventStatus: 'Open',
            };
            if (this.startYmd && this.endYmd) {
                let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
                let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
                params["registeredDateRange.from"] = from;
                params["registeredDateRange.to"] = to;
            }
        },

        reset() {
            this.currentPage = 0;
            this.keyword = "";
            this.keywordType = "all";
            this.startYmd = null;
            this.endYmd = null;
        },
        onUpdateAiDetecting(selectAiDetecing) {
            //추후 ai서버와 연결 api메소드 추가
            console.log(selectAiDetecing);
        },



        toggleCheck(index) {
            const idx = this.checkedStations.indexOf(index);
            if (idx > -1) {
                // 이미 선택된 경우 선택 해제
                this.checkedStations.splice(idx, 1);
            } else {
                // 선택되지 않은 경우 선택
                this.checkedStations.push(index);
            }
        },
        selectAll() {
            this.stationDeviceList.forEach((item) => {
                this.checkedStations.push(item.device.stationDeviceId);
            });
        },
        deselectAll() {
          this.checkedStations = [];
        },
        stationScheduleChangeAll(isScheduleRun) {
          if(this.checkedStations.length === 0){
            alert('스테이션을 선택해 주세요.');
          }else{
            this.updateStationDeviceScheduleChangeList(this.checkedStations, isScheduleRun);
          }
        },
        stationScheduleChange(stationDeviceId, isScheduleRun){
          this.updateStationDeviceScheduleChange(stationDeviceId, isScheduleRun)
        },

        stationOpen(stationDeviceId) {
            console.log(stationDeviceId);
            if(stationDeviceId != null) {
                stationPopup('stationControl', stationDeviceId)
            }
        },
    },
    beforeDestroy() {
      onWebSocketUnmounted();
      if(this.updateClockTimer != null) {
        clearInterval(this.updateClockTimer);
      }
    },

};
</script>
